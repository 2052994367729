<template>
  <div>
    <div class='sticky top-0 bg-white z-10 pt-8'>
      <h1 class='text-xl font-semibold uppercase'>
        {{title}}
      </h1>
      <h3 class='text-sm text-gray-800'>
        {{subTitle}}
      </h3>
      <div v-if='!isNewInvestment'
        class='tab-container border-b pl-8 flex flex-row justify-start mt-16 mb-8 text-sm text-gray-600 overflow-x-auto'>
        <div class='tab' :class='selectedStyle("metadata")'    @click='setActiveTab("metadata")'>Data</div>
        <div class='tab' :class='selectedStyle("reports")'     @click='setActiveTab("reports")'>Reports</div>
        <div class='tab' :class='selectedStyle("contacts")'    @click='setActiveTab("contacts")'>Contacts</div>
        <div class='tab flex flex-row items-center gap-x-1' :class='selectedStyle("approvals")'   @click='setActiveTab("approvals")'>
          Approvals
          <el-badge v-if='hasApprovalRequests'
            :value='drilldownApprovalRequestCount'
            class='top-0 right-0'>
          </el-badge>
        </div>
        <div class='tab' :class='selectedStyle("files")'       @click='setActiveTab("files")'>Files</div>
        <div class='tab' :class='selectedStyle("history")'     @click='setActiveTab("history")'>Updates</div>
        <div class='tab' :class='selectedStyle("comparables")' @click='setActiveTab("comparables")'>Comparables</div>
        <div class='tab' :class='selectedStyle("plan")'        @click='setActiveTab("plan")'>Plan</div>
      </div>
    </div>
    <component
      :is='tabContentComponent'
      @update-selected-tab='setActiveTab' />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import cloneDeep from 'lodash/cloneDeep'
import InvestmentApprovals      from '@/views/dashboard/InvestmentApprovals.vue'
import InvestmentComparables    from '@/views/dashboard/InvestmentComparables.vue'
import InvestmentContacts       from '@/views/dashboard/InvestmentContacts.vue'
import InvestmentFiles          from '@/views/dashboard/InvestmentFiles.vue'
import MsFiles                  from '@/views/dashboard/MsFiles.vue'
import InvestmentHistory        from '@/views/dashboard/InvestmentHistory.vue'
import InvestmentPlan           from '@/views/dashboard/InvestmentPlan.vue'
import InvestmentMetadata       from '@/views/dashboard/InvestmentMetadata.vue'
import InvestmentReports        from '@/views/dashboard/InvestmentReports.vue'

export default {
  name: 'InvestmentDrilldown',
  components: {
    InvestmentComparables,
    InvestmentContacts,
    InvestmentFiles,
    MsFiles,
    InvestmentHistory,
    InvestmentMetadata,
    InvestmentApprovals,
    InvestmentReports,
    InvestmentPlan,
  },
  data () {
    return {
      activeTab: 'metadata',
    }
  },
  watch: {
    drilldownInvestmentId: {
      handler: function (newId) {
        if (newId) {
          this.getInvestmentDrilldownOverview()
        } else {
          this.setActiveTab('metadata')
        }
      },
      immediate: true
    },
  },
  computed: {
    ...mapState('investments', [
      'drilldownInvestment',
      'drilldownApprovalRequestCount'
    ]),
    ...mapGetters('investments', [
      'isInvestmentReadyToMove',
      'isNewInvestment',
      'drilldownInvestmentId',
    ]),
    ...mapGetters('companySettings', [
      'isMsStorageMode',
    ]),
    tabContentComponent () {
      switch (this.activeTab) {
        case 'metadata':
          return 'investment-metadata'
        case 'comparables':
          return 'investment-comparables'
        case 'contacts':
          return 'investment-contacts'
        case 'approvals':
          return 'investment-approvals'
        case 'files':
          return this.isMsStorageMode ? 'ms-files' : 'investment-files'
        case 'history':
          return 'investment-history'
        case 'reports':
          return 'investment-reports'
        case 'plan':
          return 'investment-plan'
        default:
          return 'div'
      }
    },
    title () {
      return (this.isNewInvestment) ? '새로 만들기' : this.drilldownInvestment.entity_name
    },
    subTitle () {
      return this.drilldownInvestment.investment_name
    },
    hasApprovalRequests () {
      return this.drilldownApprovalRequestCount > 0
    },
  },
  methods: {
    ...mapActions('investments', [
      'resetEditing',
      'getInvestmentDrilldownOverview'
    ]),
    setActiveTab (tab) {
      this.activeTab = tab
      // update router query params for copying and pasting
      if (this.$route.query.tab !== tab) {
        let newQueryParams = cloneDeep(this.$route.query)
        newQueryParams['tab'] = tab
        this.$router.replace({ query: newQueryParams })
      }
      // update local storage so that app remembers what you are looking at often
      if (localStorage.getItem('lastActiveTab') !== tab) {
        localStorage.setItem('lastActiveTab', tab)
      }
    },
    selectedStyle (tab) {
      return (this.activeTab === tab) ? 'selected' : ''
    },
    setupActiveTab () {
      // need to be able to filter later based on company type (case vs investment)
      const drilldownTabs = [
        'metadata',
        'comparables',
        'contacts',
        'approvals',
        'files',
        'history',
        'reports',
        'plan',
      ]
      let queryParamTab   = this.$route.query.tab
      let localStorageTab = localStorage.getItem('lastActiveTab')

      if (queryParamTab && drilldownTabs.includes(queryParamTab)) {
        this.setActiveTab(queryParamTab)
      } else if (localStorageTab && drilldownTabs.includes(localStorageTab)) {
        this.setActiveTab(localStorageTab)
      } else {
        this.setActiveTab('metadata')
      }
    },
  },
  mounted () {
    this.setupActiveTab()
  },
  beforeDestroy () {
    let params = cloneDeep(this.$route.query)
    if (params['investment_id']) {
      delete params['investment_id']
    }
    if (params['tab']) {
      delete params['tab']
    }
    this.$router.replace({ query: params }).catch(() => {})
    this.resetEditing()
  }
}
</script>

<style lang='scss' scoped>

  .tab-container {
      margin-left: -2rem;
    }

  .tab {
    @apply px-4 pb-2 pt-4 uppercase tracking-wide cursor-pointer whitespace-no-wrap;
  }

  .tab:hover {
    @apply bg-gray-100;
  }

  .tab.selected {
    @apply font-semibold;
    color: #0D4C76;
    border-bottom: solid 2px #0D4C76;
  }

  @media (min-width: 800px) {
    .tab-container {
      margin-left: -4rem;
      margin-right: -4rem;
    }

    .tab {
      @apply px-8;
    }
  }
</style>
