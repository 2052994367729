<template>
  <tr class='text-sm' :class='checkedCss'>
    <td class='px-2 py-2'><input type='checkbox' class='form-checkbox mr-2' v-model='isChecked' @change='selectRequestForApproval'></td>
    <td class='border-r text-left px-2 py-2'>{{ approvalResponse.created_on }}</td>
    <td class='border-r text-left px-2 py-2'>{{ approvalResponse.requested_user_name }}</td>
    <td class='text-left px-2 py-2'>{{ approvalResponse.approval_type }}</td>
    <td class='text-left px-2 py-2'>{{ approvalResponse.title }}</td>
    <td class='text-left px-2 py-2'><div v-html='approvalResponse.detail' v-linkified class='linked-text-container' /></td>
  </tr>
</template>

<script>

export default {
  name: 'NeedApprovalRow',
  props: ['approvalResponse'],
  data () {
    return {
      isChecked: false,
    }
  },
  computed: {
    checkedCss () {
      return (this.isChecked) ? 'bg-blue-100' : ''
    },
  },
  methods: {
    selectRequestForApproval () {
      this.$emit('update-selected-request', this.approvalResponse, this.isChecked)
    },
  },
}
</script>
