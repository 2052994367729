<template>
  <div>
    <div class='mb-8'>
      <label class='form-label mr-3'>보고서 종류</label>
      <select class='form-select' @change='getInvestmentReportFields(reportType)'
                                  v-model='reportType'>
        <option v-for='type in reportTypes'
                :value='type'
                :key='`report-type-${type}`'>
          {{ type }}
        </option>
      </select>
      <button @click='viewReport'
        class='ml-5 p-1 underline text-blue-700'>
        {{ reportType }} 미리보기
      </button>
    </div>
    <div class='pb-24 text-xs'>
      <header-divided-custom-fields
        v-for='(fields, index) in fieldsDividedByHeaders'
        :key='`headerSection-${index}`'
        :fields='fields'
        :is-horizontal='horizontalInputs'
        class='my-4' />
    </div>
    <portal to='sidepanel-footer'>
      <investment-metadata-action-buttons
        :investment='drilldownInvestment'
        :is-valid-form='true'
        :default-view-mode='false'/>
    </portal>
  </div>
</template>

<script>

import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import cloneDeep                                          from 'lodash/cloneDeep'
import InvestmentMetadataActionButtons from '@/views/dashboard/InvestmentMetadataActionButtons.vue'
import HeaderDividedCustomFields       from '@/views/dashboard/HeaderDividedCustomFields.vue'

export default {
  name: 'InvestmentReport',
  components: {
    InvestmentMetadataActionButtons,
    HeaderDividedCustomFields,
  },
  data () {
    return {
      horizontalInputs: true,
      reportType: '투자심의위원회',
    }
  },
  computed: {
    ...mapState('investments', [
      'drilldownInvestment',
    ]),
    ...mapState('customFields', [
      'reportTypes'
    ]),
    ...mapState('approvals', [
      'requestTypes',
    ]),
    ...mapGetters('investments', [
      'customFields',
      'drilldownInvestmentId',
    ]),
    headerSortIndices () {
      return this.customFields.filter(field => field.field_type === 'sections').map(field => field.sort_index)
    },
    currentRequestType () {
      var requestType = this.requestTypes.find(type => type.name === this.reportType)
      return requestType ? requestType : ''
    },
    fieldsDividedByHeaders () {
      let arr = []
      if (this.headerSortIndices.length > 0) {
        let prev = cloneDeep(this.customFields)
        let arr2 = []
        let subIndex = 0
        this.headerSortIndices.forEach(sortIndex => {
          arr2 = prev.filter(field => field.sort_index < sortIndex)
          subIndex = arr2.length
          arr.push(arr2)
          prev.splice(0, subIndex)
        })
        arr2 = prev.filter(field => field.sort_index >= this.headerSortIndices[this.headerSortIndices.length-1])
        arr.push(arr2)

      } else {
        arr.push(this.customFields)
      }
      return arr
    },
  },
  watch: {
    'drilldownInvestment.id': {
      handler: function (newId) {
        if (newId > 0) {
          this.getInvestmentReportFields(this.reportType)
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('investments', [
      'getInvestmentReportFields',
    ]),
    ...mapMutations('investments', [
      'setDrilldownIsViewMode'
    ]),
    viewReport () {
      let routeData = this.$router.resolve({ name: this.currentRequestType.routerLink,
                                             query: {investment_id: this.drilldownInvestmentId,
                                                     title: this.currentRequestType.title}})
      window.open(routeData.href, '_blank')
    }
  },
  mounted () {
    this.setDrilldownIsViewMode(false)
  },
  beforeDestroy () {
    this.setDrilldownIsViewMode(true)
  }
}
</script>
