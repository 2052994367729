<template>
  <div>
    <a v-if='viewLink' :href='url' target='_blank'><external-link-icon /></a>
    <iframe v-else-if='url' id='sharepoint-iframe' :src='url' class='w-full' style='height: calc(100vh - 16rem);' type='text/html' @load='checkIframeExtension'></iframe>
    <div v-if='!url'>
      <p>Sharepoint Folder is being created. If the folder does not appear for a while you can retry again.</p>
      <button
        class='border rounded-md w-24 h-10 text-white text-sm font-semibold mt-4'
        style='background-color: #223645;'
        :disabled='disabled'
        @click='retryCreateFolder'>다시 시도</button>
    </div>
    <p id='extensionIframeMessageContainer' class='mt-2' v-show='!hasExtension'></p>
  </div>
</template>

<script>

import { ExternalLinkIcon } from '@vue-hero-icons/outline'
import { mapActions, mapGetters }        from 'vuex'
import ChromeExtensionHelpers from '@/utils/chrome-extension-helpers'

export default {
  name: 'MsFiles',
  components: { ExternalLinkIcon },
  data () {
    return {
      disabled: false,
      hasExtension: ChromeExtensionHelpers.hasExtension
    }
  },
  computed: {
    ...mapGetters('investments', [
      'drilldownInvestmentMsDriveUrls',
    ]),
    url () {
      let folderStr = encodeURIComponent('파일')
      return this.drilldownInvestmentMsDriveUrls.find(url => url.includes(folderStr)) || ''
    },
    viewLink () {
      return this.url && !this.hasExtension
    }
  },
  methods: {
    ...mapActions('investments', [
      'createMsFolder',
    ]),
    retryCreateFolder () {
      this.disabled = true
      this.createMsFolder().then(() => {
        setTimeout((() => this.disabled = false), 5000)
      })
    },
    checkIframeExtension () {
      ChromeExtensionHelpers.checkIframeExtension()
      this.hasExtension = ChromeExtensionHelpers.hasExtension
    },
  },
}
</script>
