<template>
  <tr v-if='editMode' class='approval-requests-row text-sm'>
    <td class='pr-2 border-r'>
      {{ updatingApprovalRequest.created_on }}
    </td>
    <td class='px-2'>
      {{ updatingApprovalRequest.user_name }}
    </td>
    <td class='px-2 border-r'>
      <user-search
        :user-ids='updatingApprovalRequest.approval_response_user_id'
        :user-names='updatingApprovalRequest.approval_response_user_name'
        :is-multiple='true'
        @update-selected-user='updateSelectedUsers' />
    </td>
    <td class='px-2'>
      <select
        v-model='updatingApprovalRequest.approval_type'
        class='form-select relative w-full'>
        <option
          v-for='type in requestTypes'
          :key='`request-type-dropdown-${type.name}`'
          :disabled='isLine(type)'>
          {{type.name}}
        </option>
      </select>
    </td>
    <td class='px-2'><input class='form-input w-full' type='text' v-model='updatingApprovalRequest.title'></td>
    <td class='px-2'><input class='form-input w-full' type='text' v-model='updatingApprovalRequest.detail'></td>
    <td class='px-2'></td>
    <td class='pl-2 flex flex-row justify-end'>
      <button
        v-if='isNew'
        class='flex flex-row items-center gap-1 text-sm uppercase px-4 py-3 bg-black border rounded-md border-transparent text-white hover:shadow-md opacity-75 hover:opacity-100 disabled:opacity-25'
        :disabled='emptyApprover'
        @click='addApproval'>
        <plus-icon class='inline-block' />
        승인 요청
      </button>
    </td>
  </tr>
  <tr v-else
    class='approval-requests-row border-b border-t text-sm'>
    <td class='py-1 pr-2 border-r'>{{ approvalRequest.created_on }}</td>
    <td class='py-1 px-2'>{{ approvalRequest.user_name }}
      <span class='text-gray-500' v-if='isMyRequest(approvalRequest.user_id)'>(본인)</span></td>
    <td class='py-1 px-2 border-r'>
      <div v-for='name in approvalResponseUserNames' :key='name'>
        <component :is='userStatusIcon(name)' class='inline-block h-4' />
        {{ name }}
      </div>
    </td>
    <td class='py-1 px-2'>
      <button v-if='isReportType'
        @click='viewReport'
        class='p-1 underline pointer'>
        {{ approvalRequest.approval_type }}
        </button>
        <span v-else>
          {{ approvalRequest.approval_type }}
        </span>
    </td>
    <td class='py-1 px-2'>{{ approvalRequest.title}}</td>
    <td class='py-1 px-2'><div v-html='approvalRequest.detail' v-linkified class='linked-text-container' /></td>
    <td class='py-1 px-2 uppercase'>
      <component :is='statusIcon' class='inline-block h-4' />
      {{ approvalRequest.status}}
      <span class='text-xs text-gray-500 inline-block ml-1' v-if='approvalRequest.status === "rejected"'>
        ({{ rejectedComments(approvalRequest.approval_responses) }})
      </span>
    </td>
    <td class='py-1 pl-2 text-right flex flex-row justify-end'>
      <button
        class='flex flex-row gap-x-px items-center text-xs pl-1 pr-3 py-2 border rounded-md border-transparent hover:border-red-200 text-gray-500 hover:text-red-600 opacity-75 hover:opacity-100 hover:shadow-md'
        :class='notMyPendingRequest(approvalRequest)'
        @click='deleteApprovalRequest(approvalRequest.id)'>
        <x-circle-icon class='inline-block h-5' />
        <span>삭제</span>
      </button>
    </td>
  </tr>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { PlusIcon, CheckIcon, CheckCircleIcon, QuestionMarkCircleIcon, XCircleIcon, XIcon } from '@vue-hero-icons/outline'
import UserSearch from '@/components/UserSearch.vue'
import cloneDeep  from 'lodash/cloneDeep'
import dayjs      from 'dayjs'

export default {
  name: 'ApprovalRequestsRow',
  components: {
    PlusIcon,
    QuestionMarkCircleIcon,
    CheckCircleIcon,
    CheckIcon,
    XCircleIcon,
    XIcon,
    UserSearch
  },
  props: ['approvalRequest', 'isNew'],
  data () {
    return {
      updatingApprovalRequest: {
        id: 0,
        investment_id: 0,
        investment_name: '',
        approval_response_user_id: [],
        approval_response_user_name: '',
        user_name: '',
        approval_type: '',
        created_on: dayjs().format('YYYY-MM-DD'),
        title: '',
        detail: '',
        status: '',
        approval_response: [],
      },
      editMode: this.isNew,
    }
  },
  watch: {
    isNew: {
      handler: function (newVal) {
        if (newVal) {
          this.updatingApprovalRequest.user_name = this.currentUserName
        }
      },
      immediate: true
    },
    approvalRequest: {
      handler: function (newVal) {
        if (newVal) {
          this.updatingApprovalRequest = cloneDeep(this.approvalRequest)
          this.updatingApprovalRequest.approval_response_user_id = newVal.approval_responses.map(approval_response => approval_response.user_id)
          this.updatingApprovalRequest.approval_response_user_name = newVal.approval_responses.map(approval_response => approval_response.user_name)
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters('investments', [
      'drilldownInvestmentId'
    ]),
    ...mapGetters('users', [
      'currentUserId',
      'currentUserName'
    ]),
    ...mapState('approvals', [
      'requestTypes',
    ]),
    approvalResponseUserNames () {
      return (this.updatingApprovalRequest.approval_responses) ? this.updatingApprovalRequest.approval_responses.map(approval_response => approval_response.user_name) : ''
    },
    statusIcon () {
      if (this.approvalRequest.status === 'approved') {
        return 'check-icon'
      } else if (this.approvalRequest.status === 'rejected') {
        return 'x-icon'
      } else {
        return 'question-mark-circle-icon'
      }
    },
    currentRequestType () {
      var requestType = this.requestTypes.find(type => type.name === this.updatingApprovalRequest.approval_type)
      return requestType ? requestType : ''
    },
    isReportType () {
      return this.currentRequestType.type === 'report'
    },
    emptyApprover () {
      return this.updatingApprovalRequest.approval_response_user_id.length === 0
    },
  },
  methods: {
    ...mapActions('investments', [
      'createApprovalRequest',
      'deleteApprovalRequest',
    ]),
    userStatusIcon (name) {
      var approval_response = this.approvalRequest.approval_responses.find(approval_response => approval_response.user_name === name)
      if (approval_response) {
        if (approval_response.status === 'approved') {
          return 'check-icon'
        } else if (approval_response.status === 'rejected') {
          return 'x-icon'
        } else {
          return 'question-mark-circle-icon'
        }
      }
      else {
        return ''
      }
    },
    cancelEdit () {
      this.editMode = false
    },
    isMyRequest (userId) {
      return (userId === this.currentUserId)
    },
    notMyPendingRequest (approvalRequest) {
      return (!this.isMyRequest(approvalRequest.user_id) ||
              approvalRequest.status === 'approved' ||
              approvalRequest.status === 'pending')
             ? 'hidden' : 'flex'
    },
    addApproval () {
      this.updatingApprovalRequest.investment_id = this.drilldownInvestmentId
      this.createApprovalRequest(this.updatingApprovalRequest).then(() => {
        this.resetLocalApprovalRequest()
      })
    },
    resetLocalApprovalRequest () {
      this.updatingApprovalRequest = {}
      this.updatingApprovalRequest.created_on                  = dayjs().format('YYYY-MM-DD')
      this.updatingApprovalRequest.user_name                   = this.currentUserName
      this.updatingApprovalRequest.approval_response_user_id   = []
      this.updatingApprovalRequest.approval_response_user_name = ''
    },
    rejectedComments (approvalResponse) {
      let rejectedApprovalResponses = approvalResponse.filter(approval_response => approval_response.status === 'rejected')
      let resultString = ''
      rejectedApprovalResponses.forEach(approval_response => {
        resultString = resultString + approval_response.user_name + ':' + approval_response.comment + ' '
      })
      return resultString
    },
    isLine (type) {
      return type.type === 'line'
    },
    viewReport () {
      let routeData = this.$router.resolve({ name: this.currentRequestType.routerLink,
                                             query: {investment_id: this.drilldownInvestmentId,
                                                     approval_request_id: this.approvalRequest.id,
                                                     title: this.currentRequestType.title}})
      window.open(routeData.href, '_blank')
    },
    updateSelectedUsers (ids, nameAndEmails) {
      this.updatingApprovalRequest.approval_response_user_id = ids
      this.updatingApprovalRequest.approval_response_user_name = nameAndEmails
    }
  },
}
</script>
