<template>
  <div>
    <investment-contacts-add />
    <table
      v-if='hasInvestmentContacts'
      class='mt-12 w-full table-fixed text-sm'>
      <thead>
        <tr class='border-b'>
          <th class='pr-2 py-2 w-32 text-left border-r'>이름</th>
          <th class='px-2 py-2 w-32 text-left border-r'>회사</th>
          <th class='px-2 py-2 w-32 text-left border-r'>전화</th>
          <th class='px-2 py-2 w-32 text-left border-r'>휴대폰</th>
          <th class='px-2 py-2 w-32 text-left border-r'>팩스</th>
          <th class='px-2 py-2 w-32 text-left border-r'>email</th>
          <th class='px-2 py-2 text-left border-r'>역할</th>
          <th class='px-2 py-2 w-32 text-left border-r'>문자</th>
          <th class='px-2 py-2 w-16 text-center'></th>
        </tr>
      </thead>
      <tbody>
        <investment-contacts-row
          v-for='contact in contactsInList'
          :key='`contact-on-investment-${contact.id}`'
          :contact='contact'
          @selectSmsContact='selectSmsContact'
          class='border-b' />
      </tbody>
    </table>
    <div v-else class='text-center p-16 bg-gray-100 mt-2'>
      {{drilldownInvestment.entity_name}} has no linked contacts
    </div>
    <portal to='modals'>
      <contact-sms :contact='selectedSmsContact' />
    </portal>
    <portal to='sidepanel-footer'>
      <div v-if='hasChanges'
        class='justify-between w-full bg-gray-100 pl-16 pr-8 py-4 text-sm shadow-lg'>
        <button class='inline-block py-3 px-12 text-white rounded-md font-semibold border border-transparent hover:border-blue-500 hover:shadow-md' style='background-color: #223645;'
          @click='updateContactsForInvestment'>
          저장
          <span class='font-normal'>{{changesString}}</span>
        </button>
        <button class='inline-block mx-4 px-4 py-3 bg-transparent text-gray-600 hover:text-gray-900 border border-transparent rounded-md hover:border-gray-600 hover:shadow-md'
          @click='cancelEdits'>
          취소
        </button>
      </div>
    </portal>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { mapMultiRowFields }                from 'vuex-map-fields'
import ContactSms            from '@/views/contacts/ContactSms.vue'
import InvestmentContactsAdd from '@/views/dashboard/InvestmentContactsAdd.vue'
import InvestmentContactsRow from '@/views/dashboard/InvestmentContactsRow.vue'


export default {
  name: 'InvestmentContacts',
  components: {
    ContactSms,
    InvestmentContactsAdd,
    InvestmentContactsRow,
  },
  data () {
    return {
      selectedSmsContact: {}
    }
  },
  watch: {
    'drilldownInvestmentId': {
      handler: function (newVal) {
        this.getContactsForInvestment(newVal)
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters('investments', [
      'drilldownInvestmentId',
      'drilldownInvestmentContactsUpdated',
    ]),
    ...mapState('investments', [
      'drilldownInvestment',
    ]),
    ...mapMultiRowFields('investments', [
      'drilldownInvestmentContacts',
    ]),
    hasInvestmentContacts () {
      return this.drilldownInvestmentContacts.length > 0
    },
    deleteCount () {
      return this.drilldownInvestmentContactsUpdated.filter(contact => contact.to_delete).length
    },
    justEditCount () {
      return this.drilldownInvestmentContactsUpdated.filter(contact => !contact.to_delete).length
    },
    changesString () {
      return `(수정: ${this.justEditCount}, 삭제: ${this.deleteCount})`
    },
    hasChanges () {
      return this.drilldownInvestmentContactsUpdated.length > 0
    },
    contactsInList () {
      return this.drilldownInvestmentContacts.filter(contact => !contact.to_delete)
    },
    showContactSms () {
      return this.selectedSmsContact.id
    }
  },
  methods: {
    ...mapActions('investments', [
      'getContactsForInvestment',
      'updateContactsForInvestment'
    ]),
    cancelEdits () {
      this.getContactsForInvestment(this.drilldownInvestmentId)
    },
    selectSmsContact (contact) {
      this.selectedSmsContact = contact
      this.$store.dispatch('modalOpen', {width: 75})
    },
  },
}
</script>
