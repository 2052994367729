<template>
  <div>
    <h1 class='text-lg mb-16 uppercase text-gray-600'>All Investments for: <span class='font-semibold text-gray-800'>{{drilldownInvestment.entity_name}}</span></h1>
    <investment-card v-for='investment in sameEntityInvestments'
          :investment='investment'
          :key='`same-entity-investment-${investment.id}`'
          @click.native='getInvestmentData(investment.id)'
          :class='sameInvestmentCSS(investment.id)'></investment-card>
  </div>
</template>

<script>

import { mapActions, mapState } from 'vuex'
import investmentsApi  from '@/api/v1/investments'
import InvestmentCard  from '@/views/dashboard/InvestmentCard.vue'


export default {
  name: 'InvestmentComparables',
  components: {
    InvestmentCard,
  },
  data () {
    return {
      sameEntityInvestments: []
    }
  },
  watch: {
    'drilldownInvestment.id': {
      handler: function () {
        this.getFilteredInvestments()
      },
      immediate: true
    },
  },
  computed: {
    ...mapState('investments', [
      'drilldownInvestment',
    ]),
    ...mapState('dashboardViews', [
      'currentDashboardView',
    ]),
    getInvestmentsParams () {
      return {
        entity_id: this.drilldownInvestment.entity_id,
        dashboard_view_id: this.currentDashboardView.id
      }
    }
  },
  methods: {
    ...mapActions('investments', [
      'getInvestmentsFiltered',
      'getInvestmentData'
    ]),
    getFilteredInvestments () {
      investmentsApi.getInvestments(this.getInvestmentsParams).then(resp => {
        this.sameEntityInvestments = resp
      })
    },
    sameInvestmentCSS (investmentId) {
      return (this.drilldownInvestment.id === investmentId) ? 'opacity-25' : ''
    },
  }
}
</script>
