<template>
  <div>
    <div>
      <h1 class='text-xl font-bold'>자산배분계획
        <button @click='exportExcel'
          class='block border px-12 py-2 text-white text-sm font-semibold rounded-md hover:border-blue-300 hover:shadow-md float-right'
          style='background-color: #223645;'>
          엑셀전환
        </button>
        <button @click='savePlan'
          class='block border px-12 py-2 text-white text-sm font-semibold rounded-md hover:border-blue-300 hover:shadow-md float-right'
          style='background-color: #223645;'>
          저장
        </button>
      </h1>
    </div>
    <div class='bg-gray-100 mt-4'>
      <div class='p-2 flex flex-row'>
        <v-select
          v-model='selectedDetails'
          label='asset_class'
          :options='selectOptions'
          :close-on-select='true'
          class='w-32'>
        </v-select>
      </div>
      <div class='p-2 flex flex-row'>
        <label class='w-32'>투자단가</label>
        <span class='w-40 text-right'>{{ numberFormat(selectedOption.investment_price) }} / 주</span>
      </div>
      <div class='p-2 flex flex-row'>
        <label class='w-32'>투자주수</label>
        <span class='w-40 text-right'>{{ numberFormat(selectedOption.investment_quantity) }} / 주</span>
      </div>
      <div class='p-2 flex flex-row'>
        <label class='w-32'>투자금액</label>
        <span class='w-40 text-right'>{{ numberFormat(selectedOption.investment_amount) }} / 원</span>
      </div>
      <div class='p-2 flex flex-row'>
        <label class='w-32'>투자일(예정)</label>
        <span class='w-40 text-right'>{{ selectedOption.investment_date }}</span>
      </div>
      <div class='p-2 flex flex-row'>
        <label class='w-32'>예상회수일</label>
        <span class='w-40 text-right'>{{ selectedOption.collection_date }}</span>
      </div>
    </div>
    <table id='assetAllocation' class='mt-10 w-full'>
      <tbody class='hidden'>
        <tr>
          <td class='text-center' colspan='13'>자산배분계획</td>
        </tr>
        <tr>
          <td>종목명</td>
          <td colspan='2'>{{ drilldownInvestment.investment_name }}</td>
          <td>투자단가</td>
          <td colspan='2'>{{selectedDetails.investment_price}}</td>
        </tr>
        <tr>
          <td></td>
          <td colspan='2'></td>
          <td>투자주식수</td>
          <td colspan='2'>{{selectedDetails.investment_quantity}}</td>
        </tr>
        <tr>
          <td>예상회수일</td>
          <td colspan='2'>{{selectedDetails.collection_date}}</td>
          <td>투자금액</td>
          <td colspan='2'>{{selectedDetails.investment_amount}}</td>
        </tr>
        <tr>
          <td>투자일(예정)</td>
          <td colspan='2'>{{selectedDetails.investment_date}}</td>
        </tr>
        <tr>
        </tr>
      </tbody>
      <thead class='text-center'>
        <tr>
          <th class='border' colspan='1' rowspan='2'>펀드코드</th>
          <th class='border' colspan='1' rowspan='2'>펀드명</th>
          <th class='border' colspan='1' rowspan='2'>수량</th>
          <th class='border' colspan='1' rowspan='2'>투자금액<br>(원)</th>
          <th class='border' colspan='3'>개별자산투자비중</th>
          <th class='border' colspan='2'>비시장성</th>
          <th class='border' colspan='2'>만기미스매칭</th>
          <th class='border' colspan='2'>운용대상</th>
          <th class='border' colspan='1' rowspan='2'>비고</th>
        </tr>
        <tr class='font-semibold whitespace-no-wrap'>
          <th class='border'>제안서</th>
          <th class='border'>본건</th>
          <th class='border'>Y/N</th>
          <th class='border'>한도</th>
          <th class='border'>집행</th>
          <th class='border'>펀드만기</th>
          <th class='border'>Y/N</th>
          <th class='border'>주목적 투자</th>
          <th class='border'>섹터적정성 평가</th>
        </tr>
      </thead>
      <tbody v-for='(plans, accountName) in investmentPlansByAccountName' :key='`account-name-${accountName}`'>
        <tr v-for='plan in plans'
          :key='`investment-plan-${plan.fund_code}`'
          class='text-center uppercase'>
          <td class='border'>{{ plan.fund_code }}</td>
          <td class='border'>{{ plan.fund_short_name }}</td>
          <td class='border text-right'>
            <vue-numeric
              v-model='plan.investment_quantity'
              output-type='number'
              class='form-input text-right'
              :minus='true'
              separator=','></vue-numeric>
            <!-- <input type='number' class='text-right' v-model='plan.investment_quantity' /> -->
          </td>
          <td class='border text-right'>{{ numberFormat(investmentAmount(plan)) }}</td>
          <td class='border text-right'>{{ plan.fund_proposal_weight }} %</td>
          <td class='border text-right'>{{ investmentRatio(plan) }} %</td>
          <td class='border text-center' :class='warnInvestmentRatioClass(plan)'>{{ investmentRatioValidText(plan) }}</td>
          <td class='border text-right'>{{ plan.fund_proposal_weight }} %</td>
          <td class='border text-right'></td>
          <td class='border text-center'>{{ fundEndDateText(plan) }}</td>
          <td class='border text-center' :class='warnEndDateClass(plan)'>{{ collectionDateValidText(plan) }}</td>
          <td class='border text-center'>{{ plan.fund_target_investments.join(', ') }}</td>
          <td class='border'></td>
          <td class='border'></td>
        </tr>
        <tr class='font-semibold'>
          <td class='border text-center' colspan='2'>{{ accountName }}</td>
          <td class='border text-right' colspan='1'>{{totalAccountInvestmentQuantity(plans)}} 주</td>
          <td class='border' colspan='11' />
        </tr>
      </tbody>
      <tbody>
        <tr>
          <td></td>
          <td></td>
          <td class='bg-white font-semibold text-right bottom-0' :class='totalInvestmentQuantityErrorClass'>{{ numberFormat(totalInvestmentQuantity) }} / {{ numberFormat(selectedDetails.investment_quantity) }}주</td>
          <td class='bg-white font-semibold text-right bottom-0'>{{ numberFormat(plansInvestmentAmount) }}원</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { mapMultiRowFields } from 'vuex-map-fields'
import { groupBy, maxBy, uniq } from 'lodash'
import numbro from 'numbro'
import dayjs  from 'dayjs'
import * as XLSX     from 'xlsx-js-style'

export default {
  name: 'InvestmentPlan',
  data () {
    return {
      selectedDetails: {
        asset_class: '전체',
        investment_price: 0,
        investment_quantity: 0,
        investment_amount: 0,
        investment_date: null,
        collection_date: null,
      },
    }
  },
  watch: {
    selectedDetails: {
      handler: function (newDetails) {
        if (newDetails.id) {
          this.getInvestmentPlans({investmentId: this.drilldownInvestmentId, investmentDetailId: newDetails.id})
        }
      }
    },
    drilldownInvestmentId: {
      handler: function (newId) {
        if (newId > 0) {
          this.selectedDetails.asset_class = '전체'
          this.getInvestmentDetails(this.drilldownInvestmentId)
          this.getNeedMyApprovalResponseForInvestment(newId)
          this.getApprovalRequestsForInvestment(newId)
        }
      },
      immediate: true
    },
  },
  computed: {
    ...mapMultiRowFields('funds', [
      'funds',
    ]),
    ...mapMultiRowFields('investmentPlans', [
      'investmentPlans',
    ]),
    ...mapState('investmentPlans', {
      plans: 'investmentPlans',
    }),
    ...mapState('approvals', [
      'approvers',
      'approvalRequests',
    ]),
    ...mapState('dailyPerformances', [
      'dailyPerformances',
    ]),
    ...mapState('investments', [
      'drilldownInvestment',
      'drilldownInvestmentDetails',
    ]),
    ...mapGetters('investments', [
      'drilldownInvestmentId'
    ]),
    accountNames () {
      return uniq(this.investmentPlans.map(plan => plan.fund_account_name))
    },
    investmentPlansByAccountName () {
      return groupBy(this.investmentPlans, 'fund_account_name')
    },
    requestsCount () {
      return (this.approvalRequests) ? this.approvalRequests.length : 0
    },
    approversCount () {
      return (this.approvers) ? this.approvers.length : 0
    },
    existApprovers () {
      return this.approversCount > 0
    },
    existRequests () {
      return this.requestsCount > 0
    },
    selectOptions () {
      let allQuantitySum = this.drilldownInvestmentDetails.reduce((sum, details) => {
        return sum + details.investment_quantity
      }, 0)
      let allAmountSum = this.drilldownInvestmentDetails.reduce((sum, details) => {
        return sum + (details.investment_quantity * details.investment_price)
      }, 0)
      let allPriceAvg = allQuantitySum > 0 ? Math.trunc(allAmountSum / allQuantitySum) : 0
      let latestDetails = maxBy(this.drilldownInvestmentDetails, 'collection_date')
      let latestCollectionDate = latestDetails ? latestDetails.colelction_date : null
      let allOption = {
        id: null,
        asset_class: '전체',
        investment_price: allPriceAvg,
        investment_quantity: allQuantitySum,
        investment_amount: allAmountSum,
        investment_date: latestCollectionDate,
        collection_date: latestCollectionDate,
      }
      return [allOption].concat(this.drilldownInvestmentDetails)
    },
    selectedOption () {
      return this.selectOptions.find(option => option.asset_class === this.selectedDetails.asset_class)
    },
    totalInvestmentQuantityErrorClass () {
      return (this.totalInvestmentQuantity > this.selectedDetails.investment_quantity) ? 'text-red-600' : ''
    },
    totalInvestmentQuantity () {
      return this.investmentPlans.reduce((sum, plan) => {
        let parsed = parseInt(plan.investment_quantity)
        if (Number.isInteger(parsed)) {
          return sum + parsed
        } else {
          return sum
        }
      }, 0)
    },
    totalInvestmentAmount () {
      return this.numberFormat(this.selectedDetails.investment_price * this.totalInvestmentQuantity)
    },
    plansInvestmentAmount () {
      return this.investmentPlans.reduce((sum, plan) => {
        let parsed = parseInt(plan.investment_quantity)
        if (Number.isInteger(parsed)) {
          return sum + (parsed * this.selectedDetails.investment_price)
        } else {
          return sum
        }
      }, 0)
    },
  },
  methods: {
    ...mapActions('investments', [
      'getInvestmentDetails',
      'getApprovalRequestsForInvestment',
      'getNeedMyApprovalResponseForInvestment',
    ]),
    ...mapActions('dailyPerformances', [
      'getDailyPerformances',
    ]),
    ...mapActions('investmentPlans', [
      'getInvestmentPlans',
      'createInvestmentPlans',
    ]),
    numberFormat (num) {
      return numbro(num).format({ thousandSeparated: true })
    },
    textColorCss (status) {
      switch (status) {
        case 'impossible':
          return 'text-red-800'
        case 'warning':
          return 'text-yellow-700'
        default:
          return 'text-black'
      }
    },
    investmentAmount(plan) {
      return parseFloat(plan.investment_quantity) * parseFloat(this.selectedDetails.investment_price) || 0
    },
    investmentRatio (plan) {
      var perf = this.dailyPerformances.find(perf => perf.fund_id === plan.fund_id)
      var ratio = 0

      if (perf) {
        ratio = Math.round(this.investmentAmount(plan) / perf.nav_amount * 10000, 2) / 100
      }

      return ratio
    },
    investmentRatioValid (plan) {
      return plan.fund_proposal_weight >= this.investmentRatio(plan)
    },
    investmentRatioValidText (plan) {
      return this.investmentRatioValid(plan) ? 'Y' : 'N'
    },
    fundEndDateText (plan) {
      return dayjs(plan.fund_end_date) >= dayjs('29991231') ? '개방형' : dayjs(plan.fund_end_date).format('YYYY-MM-DD')
    },
    collectionDateValid (plan) {
      return dayjs(plan.fund_end_date) >= dayjs(this.selectedDetails.collection_date)
    },
    collectionDateValidText (plan) {
      return this.collectionDateValid(plan) ? 'Y' : 'N'
    },
    totalAccountInvestmentQuantity (plans) {
      return plans.reduce((sum, plan) => {
        let parsed = parseInt(plan.investment_quantity)
        if (Number.isInteger(parsed)) {
          return sum + parsed
        } else {
          return sum
        }
      }, 0)
    },
    exportExcel () {
      var excelData = XLSX.utils.table_to_sheet(document.getElementById('assetAllocation')); // table id를 넣어주면된다
      var workBook = XLSX.utils.book_new(); // 새 시트 생성
      XLSX.utils.book_append_sheet(workBook, excelData, '배분내역서');  // 시트 명명, 데이터 지정
      XLSX.writeFile(workBook, `${this.drilldownInvestment.entity_name}_자산배분계획.xlsx`); // 엑셀파일 만듬
    },
    savePlan () {
      let params = []
      this.plans.forEach(plan => {
        if (plan.id > 0 || plan.investment_quantity > 0) {
          params.push(plan)
        }
      })
      this.createInvestmentPlans({investment_plans: params, investment_id: this.drilldownInvestmentId, investment_detail_id: this.selectedDetails.id})
    },
    warnEndDateClass (plan) {
      return this.collectionDateValid(plan) ? '' : 'bg-red-200'
    },
    warnInvestmentRatioClass (plan) {
      return this.investmentRatioValid(plan) ? '' : 'bg-yellow-300'
    },
  },
  mounted () {
    const previous_date = dayjs().subtract(1, 'days').format('YYYY-MM-DD')
    this.getDailyPerformances(previous_date)
  }
}
</script>
