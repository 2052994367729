<template>
  <div class=''>
    <div class='flex flex-row mb-3'>
      <clipboard-check-icon />
      <h1 class='font-bold ml-2 p-1 uppercase'>Needs Your Approval</h1>
      <span class='font-bold ml-1 p-1 text-gray-500'>({{approvalResponsesCount}})</span>
    </div>
    <needs-your-approval v-if='hasApprovalResponses'
      :approval-responses='approvalResponses' />
    <div class='flex flex-row mb-3'>
      <clipboard-list-icon />
      <h1 class='font-bold ml-2 p-1 uppercase'>Other Requests</h1>
      <span class='font-bold ml-1 p-1 text-gray-500'>({{requestsCount}})</span>
    </div>
    <other-requests :approval-requests='drilldownApprovalRequests' />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import NeedsYourApproval from '@/components/approvals/NeedsYourApproval.vue'
import OtherRequests     from '@/components/approvals/OtherRequests.vue'
import { ClipboardCheckIcon, ClipboardListIcon } from '@vue-hero-icons/outline'

export default {
  name: 'InvestmentApprovals',
  components: {
    NeedsYourApproval,
    OtherRequests,
    ClipboardCheckIcon,
    ClipboardListIcon
  },
  watch: {
    drilldownInvestmentId: {
      handler: function (newId) {
        if (newId > 0) {
          this.getApprovalRequestsForInvestment(newId)
        }
      },
      immediate: true
    },
  },
  computed: {
    ...mapState('investments', [
      'drilldownApprovalRequests',
    ]),
    ...mapGetters('approvals', [
      'approvalResponsesByInvestment',
    ]),
    ...mapGetters('investments', [
      'drilldownInvestmentId'
    ]),
    requestsCount () {
      return (this.drilldownApprovalRequests) ? this.drilldownApprovalRequests.length : 0
    },
    approvalResponses () {
      return this.approvalResponsesByInvestment(this.drilldownInvestmentId)
    },
    approvalResponsesCount () {
      return (this.approvalResponses) ? this.approvalResponses.length : 0
    },
    hasApprovalResponses () {
      return this.approvalResponsesCount > 0
    },
  },
  methods: {
    ...mapActions('investments', [
      'getApprovalRequestsForInvestment',
    ]),
  }
}
</script>
