<template>
  <div class='border px-8 py-4 rounded w-2/3'>
    <div class='flex flex-row justify-start text-sm mt-2'>
      <label class='block w-40 uppercase text-gray-600 leading-10'>Contact</label>
      <contact-search
        @update-selected-contact='updateSelectedContact'
        class='flex-grow'
        :contact-id='selectedContact.id'
        customCSSClasses='bg-white flex-grow' />
    </div>
    <div class='flex flex-row justify-start text-sm mt-2'>
      <label class='block w-40 uppercase text-gray-600 leading-10'>Role</label>
      <input
        class='form-input flex-grow'
        v-model='selectedContact.role'>
    </div>
    <button
      @click='addContact'
      :disabled='addContactDeActivate'
      class='inline-block mt-4 py-3 px-12 text-white text-sm rounded-md font-semibold border border-transparent hover:border-blue-500 hover:shadow-md'
      style='background-color: #223645;'>
      <plus-circle-icon class='inline-block' style='margin-top: -4px;' />
      추가
    </button>
    <portal to='modals'>
      <contact-edit v-if='showEditContact'
        @updated-contact='cancelEditing'
        :display-in-modal='true' />
    </portal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { PlusCircleIcon } from '@vue-hero-icons/outline'
import cloneDeep          from 'lodash/cloneDeep'
import ContactEdit   from '@/components/contacts/ContactEdit.vue'
import ContactSearch from '@/components/contacts/ContactSearch.vue'

const emptyInvestmentContactLink = {
  id:                0,
  investment_id:     0,
  contact_id:        0,
  to_delete:         false,
  updated_on_client: false,
  is_client:         false,
  role:              ''
}

export default {
  name: 'InvestmentContactsAdd',
  components: {
    ContactEdit,
    PlusCircleIcon,
    ContactSearch,
  },
  data () {
    return {
      showEditContact: false,
      selectedContact: cloneDeep(emptyInvestmentContactLink)
    }
  },
  computed: {
    addContactDeActivate () {
      return !(this.selectedContact)
    },
  },
  methods: {
    ...mapActions('contacts', [
      'editContact',
      'addMappingInvestmentContact',
      'getContactCustomFields',
      'cancelEditing',
    ]),
    ...mapActions('investments', [
      'addInvestmentContactLink'
    ]),
    openContactEdit (contact) {
      this.showEditContact = true
      this.editContact(contact)
      this.getContactCustomFields(contact.id)
      this.$store.dispatch('modalOpen')
    },
    addContact () {
      this.addInvestmentContactLink(this.selectedContact).then(() => {
        this.selectedContact = cloneDeep(emptyInvestmentContactLink)
      }).catch(() => {
        this.$message({
          type: 'warning',
          message: '이미 추가 되어있는 항목 입니다.'
        })
      })
    },
    updateSelectedContact (contact) {
      this.selectedContact = Object.assign(this.selectedContact, cloneDeep(contact))
      this.selectedContact.contact_id = contact.id
    },
  },
}
</script>
