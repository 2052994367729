const chrome = window.chrome
class ChromeExtensionHelpers {
  /*eslint-disable */
  static extensionMessageContainerId = '#extensionMessageContainer'
  static extensionIframeMessageContainerId = '#extensionIframeMessageContainer'
  static extensionID = process.env.VUE_APP_CHROME_EXT_ID
  static extensionURL = process.env.VUE_APP_CHROME_EXT_URL
  static urlDestination = 'https://safind.scourt.go.kr/sf/mysafind.jsp'
  static hasExtension = false

  static checkExtension () {
    if (chrome) {
      if (chrome.runtime && chrome.runtime.sendMessage) {
        chrome.runtime.sendMessage(this.extensionID, { message: 'version' }, (reply) => {
          // the reply is the object sent from the chrome extension listener
          if (reply && reply.version) {
            // if the extension is installed, good to go
            document.querySelector(this.extensionMessageContainerId).innerText = ''
            this.hasExtension = true
          }
          else {
            // if the extension isn't installed, display some default
            document.querySelector(this.extensionMessageContainerId).innerHTML = `Chrome extension <a href='${this.extensionURL}' target='_blank' class='underline text-blue-600'>설치가</a> 필요합니다.`
          }
        })
      } else {
        // if the extension isn't installed, display some default
        document.querySelector(this.extensionMessageContainerId).innerHTML = `Chrome extension <a href='${this.extensionURL}' target='_blank' class='underline text-blue-600'>설치가</a> 필요합니다.`
      }
    } else {
      // no chrome apis... are you on https?
      document.querySelector(this.extensionMessageContainerId).innerText = '대법원 사건 조회는 Chrome browser에서만 가능합니다.'
    }
  }

  static checkIframeExtension () {
    if (chrome && chrome.runtime && chrome.runtime.sendMessage) {
      chrome.runtime.sendMessage(this.extensionID, { message: 'version' }, (reply) => {
        // the reply is the object sent from the chrome extension listener
        if (reply && reply.version >= 2.0) {
          // if the extension is installed, good to go
          document.querySelector(this.extensionIframeMessageContainerId).innerText = ''
          this.hasExtension = true
        }
        else {
          // if the extension isn't installed, display some default
          document.querySelector(this.extensionIframeMessageContainerId).innerHTML = `파일 브라우저가 보이지 않으시면 Chrome extension <a href='${this.extensionURL}' target='_blank' class='underline text-blue-600'>설치가</a> 필요합니다.`
        }
      })
    } else {
      // no chrome apis... are you on https?
      document.querySelector(this.extensionIframeMessageContainerId).innerText = 'Sharepoint는 Chrome browser에서만 가능합니다.'
    }
  }

  static runExtension (params) {
    // copy info from page and send it to the extension
    let data = {}
    data.sch_bub_nm = params.jurisdiction_name
    data.sel_sa_year = params.year
    data.sa_gubun = params.gubun
    data.sa_serial = params.serial_number
    data.ds_nm = params.client_name
    data.inputsano_ch = false
    data.destination_url = this.urlDestination
    chrome.runtime.sendMessage(this.extensionID, { message: 'data', data }, (reply) => {
      if (reply && reply.success) {
        // success
      } else {
        // something went wrong
      }
    })
  }
  /*eslint-enable */
}

export default ChromeExtensionHelpers
