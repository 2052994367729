<template>
  <div class='pb-8'>
    <table class='w-full'>
      <thead>
        <tr class='text-left text-sm uppercase font-semibold'>
          <th class='pr-2 py-2 border-r'>요청 일자</th>
          <th class='px-2 py-2'>요청자</th>
          <th class='px-2 py-2 border-r w-32'>승인자</th>
          <th class='px-2 py-2'>종류</th>
          <th class='px-2 py-2'>제목</th>
          <th class='px-2 py-2'>내용</th>
          <th class='px-2 py-2 w-24'>진행상태</th>
        </tr>
      </thead>
      <tbody class='whitespace-no-wrap'>
        <approval-requests-row
          :is-new='true'
          class='border-b border-t'/>
        <approval-requests-row
          v-for='request in approvalRequests'
          :key='`approval-requests-row-${request.id}`'
          :approval-request='request'
          class='border-b border-t'/>
      </tbody>
    </table>
  </div>
</template>

<script>
import ApprovalRequestsRow from '@/components/approvals/ApprovalRequestsRow.vue'

export default {
  name: 'OtherRequests',
  components: {
    ApprovalRequestsRow,
  },
  props: [
    'approvalRequests'
  ],
}
</script>
